import React, { useEffect, useState } from "react";
import { Button, Table, Modal } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useUserAuth } from "../context/UserAuthContext";
import Loading from "./Loading";

import { db } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  updateDoc,
  doc,
} from "firebase/firestore";

const Home = () => {
  const { logOut, user } = useUserAuth();
  const [showOfferConfirmation, setShowOfferConfirmation] = useState(false);
  const navigate = useNavigate();
  const [peopleDocId, setPeopleDocId] = useState(null);
  const [offer, setOffer] = useState({ loading: true });

  
  useEffect(() => {
    const getData = async () => {
      const peopleCollectionRef = doc(db, "people",user.email);
      const peopleOfferCollectionRef = doc(db, "peopleOffer",user.email);
      const [offerData, offerStatus] = await Promise.all([getDoc(peopleCollectionRef),getDoc(peopleOfferCollectionRef)]);

      if(offerData.exists() && offerStatus.exists()){
        const offerDataDoc = offerData.data()
        const offerStatusDoc = offerStatus.data()
        setPeopleDocId(offerData.id);
        setOffer({...offerDataDoc,status: offerStatusDoc.status});
      }else{
        console.log('No Offer Found')
        setOffer({ loading: false })
      }

  
    };
    if (user.email) {
      getData();
    }
  }, [user]);

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };

  const acceptOffer = async () => {
    setShowOfferConfirmation(false);
    console.log({ peopleDocId });
    const peopleRef = doc(db, "peopleOffer", peopleDocId);
    await updateDoc(peopleRef, { status: "OFFER_ACCEPTED" });
    setOffer({...offer, status:'OFFER_ACCEPTED'})
    // alert("Offer Accepted");
  };

  const handleAcceptOffer = async () => {
    setShowOfferConfirmation(true);
  };
  const handleCloseModal = () => {
    setShowOfferConfirmation(false);
  };
  return (
    <>
      <div className="p-4 box mt-3 text-center">
        Hello Welcome <br />
        {user && user.email}
        <br />
        <br />
        {offer.status && offer.status == "OFFER_ACCEPTED" && (
          <>
            <div>
              <p className="lead">
                Sign the Internship Contract document recevied via Zoho Sign(notifications@zohosign.com) on
                your email {user.email} to get your company login credentials
              </p>
              <small>Check after 15 minutes of signing the contract received on your email.</small>
            </div>
          </>
        )}
        {offer.status && offer.status == "OFFER_SENT" && (
          <>
            <Table striped bordered hover>
              <tbody>
                <tr>
                  <th>Role</th>
                  <td>{offer.offerDetails.role}</td>
                </tr>

                <tr>
                  <th>Internship Period</th>
                  <td>{offer.offerDetails.period}</td>
                </tr>
                <tr>
                  <th>Stipend</th>
                  <td>{offer.offerDetails.payment}</td>
                </tr>
                {/* <tr>
              <th>Role</th>
              <td>Role Here</td>
            </tr> */}
              </tbody>
            </Table>

            <br />
            <Button variant="primary" onClick={handleAcceptOffer}>
              I accept the Offer
            </Button>
          </>
        )}
        {offer.loading && (
          <p>
            Please wait...
            <br />
            <Loading />
          </p>
        )}
        {!offer.status && !offer.loading && (
          <>
            <p>
              There is no offer to this email from W3 Dev or any partner Company
            </p>
            <small>Reach out to support if you think this is an Error</small>
          </>
        )}
        <br />
        <br />
      </div>
      <div className="d-grid gap-2">
        <Button variant="primary" onClick={handleLogout}>
          Log out
        </Button>
      </div>

      <Modal show={showOfferConfirmation} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Offer Acceptance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Once you accept the offer, you should not join any other Internship or
          full-time commitment as per the Internship agreement. Press "Continue,
          I accept" to accept the offer
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={acceptOffer}>
            Continue, I accept.
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Home;
