import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyA5A36ij0JDisdH_oNqBeQCl2yq4xsDXak",
  authDomain: "w3dev-helper.firebaseapp.com",
  projectId: "w3dev-helper",
  storageBucket: "w3dev-helper.appspot.com",
  messagingSenderId: "778341755570",
  appId: "1:778341755570:web:0cec5233ecf43ab79f77ab",
  measurementId: "G-67YG81SH18",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export default app;
