import "./Loading.css"

export default function () {
  return (
    <div className="load-container">
      <div className="📦"></div>
      <div className="📦"></div>
      <div className="📦"></div>
      <div className="📦"></div>
      <div className="📦"></div>
    </div>
  );
}
